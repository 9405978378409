import { useState, useEffect } from "react";
import './styles/Panel.css';
import { Navbar } from "../elements/Navbar";
import { NavbarButton } from "../elements/Navbar";
import { Home } from "./panel/Home";
import { Database } from "./panel/Database";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";

function Panel() {
  const [accessToken, setAccessToken] = useState();
  const [activeSection, setActiveSection] = useState("home");
  const [botValue, setBotValue] = useState();

  useEffect(() => {
      const searchParams = new URLSearchParams(window.location.search);
      setBotValue(searchParams.get("bot"));
      let accessTokenValue = "accessToken";
      if (botValue)
      {
        accessTokenValue = `accessToken_${botValue}`;
      }
      setAccessToken(localStorage.getItem(accessTokenValue))
  }, [botValue]);

  const handleNavItemClick = (sectionId) => {
    setActiveSection(sectionId);
  };


  if (botValue) {
    if (accessToken) {
      return (
        <div className="Panel">
          {activeSection === "home" && (
            <Home className="container" bot={botValue}></Home>
          )}

          {activeSection === "database" && (
            <Database className="container" bot={botValue}></Database>
          )}

          <Navbar>
            <NavbarButton
              active={activeSection === "home"}
              onClick={() => handleNavItemClick("home")}
              icon="uil uil-estate"
              text="Главная"
            />
            <NavbarButton
              active={activeSection === "items"}
              onClick={() => handleNavItemClick("items")}
              icon="uil uil-shopping-cart"
              text="Товары"
            />
            <NavbarButton
              active={activeSection === "database"}
              onClick={() => handleNavItemClick("database")}
              icon="uil uil-database"
              text="База данных"
            />
          </Navbar>
        </div>
      );
    }
    
  }
  
}

export { Panel };
