import React, { useState, useEffect } from "react";
import Skeleton from 'react-loading-skeleton'
import { BarComponent, DoughnutComponent } from "../../elements/Charts";
import "./styles/Referer.css";
import { useLoading } from "../../elements/Loading"

const tg = window.Telegram.WebApp;

function Referer(props) {
    const loading = useLoading();
    const [dataTraffic, setDataTraffic] = useState(null);
    const [dataProfit, setDataProfit] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [dataTrafficMonth, setDataTrafficMonth] = useState(null);
    const [dataProfitMonth, setDataProfitMonth] = useState(null);
    const [dataTrafficGeneral, setDataTrafficGeneral] = useState(null);
    const [dataProfitGeneral, setDataProfitGeneral] = useState(null);
    const [directLink, setDirectLink] = useState();
    const [websiteLink, setWebsiteLink] = useState();

    useEffect(() => {
        //loading.show();
        const fetchData = async () => {
            try {
                const request = await props.botRequest.request("post", "/webapp/referer", { userid: props.initData.user.id });
                setDataProfit(request.data.profit);
                setDataProfitMonth(request.data.profit_month);
                setDataProfitGeneral(request.data.profit_general);
                setDataTraffic(request.data.traffic);
                setDataTrafficMonth(request.data.traffic_month);
                setDataTrafficGeneral(request.data.traffic_general);
                setDirectLink(request.data.direct_link);
                setWebsiteLink(request.data.website_link);



            } catch (error) {
                console.log("Ошибка при получении данных:", error);
            } finally {
                //loading.hide();

                setIsLoaded(true);
            }
        };

        fetchData();


    }, []);

    const copy_link = (event) => {
        const elementId = event.target.id;
        const inputElement = document.getElementById(elementId.replace(/_copy/g, ''));
        const textToCopy = inputElement.value;
        const textarea = document.createElement("textarea");
        textarea.value = textToCopy;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        tg.showAlert("Ссылка скопирована в буфер обмена");

    };

    if (isLoaded) {
        return (
            <div className={props.className}>
                <div className="card" id="link">
                    <div className="cardHeader">
                        <h1>Моя ссылка</h1>
                    </div>
                    <div className="input_block">
                        <div className="input_container">
                            <label>Прямая ссылка</label>
                            <div class="input_with_button">
                                <div className="input_div">
                                    <input id="direct_link" className="input_field"
                                        type="text"
                                        defaultValue={directLink}
                                        readOnly
                                    />
                                </div>
                                <button id="direct_link_copy" className="uil uil-copy" onClick={copy_link}></button>
                            </div>
                        </div>
                        <div className="input_container">
                            <label>Ссылка на сайт</label>
                            <div class="input_with_button">
                                <div className="input_div">
                                    <input id="website_link" className="input_field"
                                        type="text"
                                        defaultValue={websiteLink}
                                        readOnly
                                    />
                                </div>
                                <button id="website_link_copy" className="uil uil-copy" onClick={copy_link}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="space"></div>
                <div className="card" id="statistics">
                    <div className="cardHeader">
                        <h1>Статистика</h1>
                    </div>

                    <div className="stats_block">
                        <div className="chart_block">
                            <label>Переходы за 30 дней</label>
                            <BarComponent data={dataTraffic} label="Переходы" />
                            <div className="stats_text">
                                <div className="stats_text_field">
                                    <label>{dataTraffic[dataTraffic.length - 1].amount}</label>
                                    <p>Сегодня</p>
                                </div>
                                <div className="stats_text_field">
                                    <label>{dataTraffic[dataTraffic.length - 2].amount}</label>
                                    <p>Вчера</p>
                                </div>
                                <div className="stats_text_field">
                                    <label>{dataTrafficMonth}</label>
                                    <p>{`Месяц (${new Date().toLocaleString('default', { month: 'long' })})`}</p>
                                </div>
                                <div className="stats_text_field">
                                    <label>{dataTrafficGeneral}</label>
                                    <p>За все время</p>
                                </div>
                            </div>
                        </div>
                        <div className="chart_block">
                            <label>Покупки за 30 дней</label>
                            <BarComponent data={dataProfit} label="Сумма" symbol="₽" />

                            <div className="stats_text">
                                <div className="stats_text_field">
                                    <label>{dataProfit[dataProfit.length - 1].amount}₽</label>
                                    <p>Сегодня</p>
                                </div>
                                <div className="stats_text_field">
                                    <label>{dataProfit[dataProfit.length - 2].amount}₽</label>
                                    <p>Вчера</p>
                                </div>
                                <div className="stats_text_field">
                                    <label>{dataProfitMonth}₽</label>
                                    <p>{`Месяц (${new Date().toLocaleString('default', { month: 'long' })})`}</p>
                                </div>
                                <div className="stats_text_field">
                                    <label>{dataProfitGeneral}₽</label>
                                    <p>За все время</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className={props.className}>
                <div className="card" id="link">
                    <div className="cardHeader">
                        <h1><Skeleton width={150}></Skeleton></h1>
                    </div>
                    <div className="input_block">
                        <div className="input_container">
                            <label><Skeleton width={100}></Skeleton></label>
                            <Skeleton width="100%" height={"2.5rem"} borderRadius="6px"></Skeleton>
                        </div>
                        <div className="input_container">
                            <label><Skeleton width={100}></Skeleton></label>
                            <Skeleton width="100%" height={"2.5rem"} borderRadius="6px"></Skeleton>
                        </div>
                    </div>
                </div>
                <div className="space"></div>
                <div className="card" id="statistics">
                    <div className="cardHeader">
                        <h1><Skeleton width={150}></Skeleton></h1>
                    </div>

                    <div className="stats_block">
                        {Array.from({ length: 2 }, (_, index) => (
                            <div className="chart_block" key={index}>
                                <label><Skeleton width={100} /></label>
                                <BarComponent data={[]} label="Переходы" />
                                <div className="stats_text">
                                    {Array.from({ length: 4 }, (_, i) => (
                                        <div className="stats_text_field" key={i}>
                                            <Skeleton width={40} />
                                            <Skeleton width={70} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        );
    }
}

export { Referer };